import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import useRequest from "hooks/useRequest";

function ConfirmModal({ week, refetch }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { postData, postRequestData } = useRequest("notify/notify-all-winners");

  async function updateEmailStatus() {
    await postData({ week }, `письма победителям периода: ${week} успешно отправлены`, onClose);
    refetch();
  }

  return (
    <>
      <Button
        w={{ sm: "100%", md: "max-content" }}
        colorScheme="blue"
        isDisabled={week === "За все время" || week === "Выберите период" ? true : false}
        onClick={onOpen}
      >
        Отправить письма
      </Button>

      <Modal closeOnOverlayClick={false} isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent mx="10px">
          <ModalHeader>Подтвердить отправку писем</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>Вы уверены что хотите отправить письма победителям?</ModalBody>

          <ModalFooter>
            <Button isLoading={postRequestData.isLoading} colorScheme="green" mr={3} onClick={updateEmailStatus}>
              Да
            </Button>
            <Button colorScheme="red" onClick={onClose}>
              нет
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmModal;
