import { useState, useEffect } from "react";
import axios from "axios";

export default function useRequestObject(path) {
  const [getRequestData, setRequestData] = useState({
    responseData: {},
    isLoading: false,
    isError: "",
  });

  let ignore = false;

  async function getData() {
    try {
      setRequestData({ ...getRequestData, isLoading: true });

      const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/${path}`);

      if (!ignore) {
        setRequestData({
          ...getRequestData,
          isLoading: false,
          responseData: data,
        });
      }
    } catch (error) {
      setRequestData({
        ...getRequestData,
        isLoading: false,
        isError: error.message,
      });
    }
  }

  useEffect(() => {
    getData();
    return () => {
      ignore = true;
    };
  }, [path]);

  return { ...getRequestData, refetch: getData };
}
