import { Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function MenuComponent({ limitOnPage, handleChangeLimitOnPage }) {
  return (
    <Menu>
      <MenuButton w={{ base: "100%", md: "max-content" }} as={Button} rightIcon={<ChevronDownIcon />}>
        {limitOnPage === "ALL" ? "Все" : limitOnPage}
      </MenuButton>

      <MenuList onClick={handleChangeLimitOnPage}>
        <MenuItem name="5">5</MenuItem>
        <MenuItem name="10">10</MenuItem>
        <MenuItem name="20">20</MenuItem>
        <MenuItem name="ALL">Все</MenuItem>
      </MenuList>
    </Menu>
  );
}
