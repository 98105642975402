import { useState } from "react";

import { Popover, PopoverTrigger, PopoverContent, Button, Input, Flex, IconButton } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";

import useRequest from "hooks/useRequest";

export default function EditCheckPattern({ id, currentValue, refetch }) {
  const [value, setValue] = useState({ typeName: currentValue });
  const { putData } = useRequest("item-type");

  async function updateCheckPattern(id) {
    await putData({ ...value, id });
    await refetch();
  }

  function getInputValue(e) {
    setValue({ ...value, [e.target.name]: e.target.value });
  }
  return (
    <Popover placement="right">
      <PopoverTrigger>
        <IconButton size="sm" icon={<EditIcon boxSize={5} />} />
      </PopoverTrigger>
      <PopoverContent p={5}>
        <Flex direction="column" gap={10}>
          <Input name="typeName" placeholder="Введите новое наименование" onChange={getInputValue} value={value.typeName} />
          <Button colorScheme="green" onClick={() => updateCheckPattern(id)}>
            Сохранить
          </Button>
        </Flex>
      </PopoverContent>
    </Popover>
  );
}
