import { Flex, Td, Text, Tr } from "@chakra-ui/react";

function WinnersTemplateTable({ data, openUserCard, openQrCard, prize }) {
  return (
    <>
      {data.map((row, i) => (
        <Tr key={i}>
          <Td>
            <Flex align="center">
              <Text w="max-content" role="button" fontSize="md" fontWeight="bold" onClick={() => openUserCard(row.account_id)}>
                {row.account_id || "-"}
              </Text>
            </Flex>
          </Td>

          <Td>
            <Flex align="center">
              <Text w="max-content" fontSize="md" fontWeight="bold">
                {row.email || "-"}
              </Text>
            </Flex>
          </Td>

          <Td>
            <Flex align="center">
              <Text w="max-content" color="gray.400" fontSize="md">
                {row.fio || "-"}
              </Text>
            </Flex>
          </Td>

          <Td>
            <Flex align="center">
              <Text w="max-content" fontSize="md" fontWeight="bold">
                {row.phone || "-"}
              </Text>
            </Flex>
          </Td>

          <Td>
            <Flex align="center" w="min-content">
              <Text w="max-content" color="gray.400" fontSize="md" role="button" onClick={() => openQrCard(row.id)}>
                {row.id || "-"}
              </Text>
            </Flex>
          </Td>

          <Td>
            <Flex align="center">
              <Text w="max-content" color="gray.400" fontSize="md">
                {prize}
              </Text>
            </Flex>
          </Td>
        </Tr>
      ))}
    </>
  );
}

export default WinnersTemplateTable;
