import { Button, Menu, MenuButton, MenuList, MenuItem } from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";

export default function CheckNotVerifiedMenu({ reason, setReason }) {
  return (
    <Menu>
      <MenuButton w={{ sm: "100%", md: "max-content" }} as={Button} rightIcon={<ChevronDownIcon />}>
        {reason}
      </MenuButton>

      <MenuList onClick={(e) => setReason(e.target.textContent)}>
        <MenuItem>Некорректные данные чека</MenuItem>
        <MenuItem>Нет акционного товара</MenuItem>
        <MenuItem>Чек не прошел проверку ФНС</MenuItem>
        <MenuItem>Данные чека нечитаемые</MenuItem>
        <MenuItem>Пользователь не выполнил условия проведения акции</MenuItem>
        <MenuItem>Дубликат</MenuItem>
        <MenuItem>Другое</MenuItem>
      </MenuList>
    </Menu>
  );
}
