import React, { useEffect, useState } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { BrowserRouter } from "react-router-dom/cjs/react-router-dom.min";

import AuthLayout from "layouts/Auth.js";
import AdminLayout from "layouts/Admin.js";
import DashboardLayout from "layouts/Dashboard.js";

import { AuthContext } from "contexts/AuthContext";

function App() {
  const [isAuth, setIsAuth] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [email, setEmail] = useState("Модератор");

  useEffect(() => {
    if (localStorage.getItem("auth")) {
      setIsAuth(true);
    } else {
      setIsAuth(false);
    }
    setIsLoading(false);
  }, []);
  return (
    <AuthContext.Provider
      value={{
        isAuth,
        setIsAuth,
        isLoading,
        setIsLoading,
        email,
        setEmail,
      }}
    >
      {isLoading ? (
        ""
      ) : (
        <BrowserRouter>
          {isAuth ? (
            <Switch>
              <Route path={`/admin`} component={AdminLayout} />
              <Route path={`/dashboard`} component={DashboardLayout} />
              <Redirect from={`/`} to="/dashboard/all-checks" />
            </Switch>
          ) : (
            <Switch>
              <Route path={`/auth/login`} component={AuthLayout} />
              <Route path={`/auth/reg`} component={AuthLayout} />
              <Redirect from={`/`} to="/auth/login" />
            </Switch>
          )}
        </BrowserRouter>
      )}
    </AuthContext.Provider>
  );
}

export default App;
