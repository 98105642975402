import { Flex, Td, Text, Table, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import { dateConvert } from "helper/dateConvert";

const UserTable = ({ data, openUserCard, setOrder, order, orderType }) => {
  function changeDateSortOrder() {
    setOrder((prev) => {
      localStorage.setItem(orderType, prev === "ASC" ? "DESC" : "ASC");

      return prev === "ASC" ? "DESC" : "ASC";
    });
  }

  return (
    <Card overflowX="scroll">
      <CardBody>
        <Table variant="striped" size="sm" colorScheme="gray">
          <Thead flex="column" justifyContent="space-between">
            <Tr color="gray.500">
              <Th color="gray.500">id пользователя</Th>
              <Th color="gray.500">ФИО</Th>
              <Th color="gray.500">Номер телефона</Th>
              <Th color="gray.500">Email пользователя</Th>
              <Th role="button" color="gray.500">
                <Flex justify="space-between" align="center" gap="10px" onClick={changeDateSortOrder}>
                  <Text>Дата и время регистрации</Text>
                  {order === "DESC" && <TriangleDownIcon w="15px" h="15px" />}
                  {order === "ASC" && <TriangleUpIcon w="15px" h="15px" />}
                </Flex>
              </Th>
            </Tr>
          </Thead>

          <Tbody>
            {data.map((row) => {
              return (
                <Tr key={row.id}>
                  <Td>
                    <Flex align="center" w="min-content">
                      <Text w="max-content" fontSize="md" fontWeight="bold" role="button" onClick={() => openUserCard(row.id)}>
                        {row.id || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text color="gray.400" fontSize="md" w="max-content">
                        {row.fio || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text fontSize="md" fontWeight="bold" w="max-content">
                        {row.phone || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text fontSize="md" fontWeight="bold" w="max-content">
                        {row.email || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text color="gray.400" fontSize="md" w="max-content">
                        {dateConvert(row.created_at) || "-"}
                      </Text>
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default UserTable;
