import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import "react-datepicker/dist/react-datepicker.css";
import "./style.css";
import { format } from "date-fns";
import { useColorMode } from "@chakra-ui/react";

const DateRangePicker = ({ setRaffleData, raffleData }) => {
  const { colorMode } = useColorMode();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleStartDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setRaffleData({ ...raffleData, raffleWeekStart: formattedDate });
    setStartDate(date);
  };

  const handleEndDateChange = (date) => {
    const formattedDate = format(date, "yyyy-MM-dd");
    setRaffleData({ ...raffleData, raffleWeekEnd: formattedDate });
    setEndDate(date);
  };

  return (
    <>
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        selectsStart
        startDate={startDate}
        endDate={endDate}
        placeholderText="Дата начала"
        dateFormat="dd/MM/yyyy"
        locale={ru}
        className={colorMode === "dark" ? "custom-datepicker-dark" : "custom-datepicker-light"}
      />

      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        selectsEnd
        startDate={startDate}
        endDate={endDate}
        placeholderText="Дата окончания"
        dateFormat="dd/MM/yyyy"
        locale={ru}
        className={colorMode === "dark" ? "custom-datepicker-dark" : "custom-datepicker-light"}
        minDate={startDate}
      />
    </>
  );
};

export default DateRangePicker;
