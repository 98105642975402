import { Flex, Button, Stack } from "@chakra-ui/react";
import { ChevronRightIcon, ChevronLeftIcon, ArrowLeftIcon, ArrowRightIcon } from "@chakra-ui/icons";

export default function PagePagination({ page, setPage, totalPages, pageInLs }) {
  function handleIncrementPage() {
    setPage((prev) => {
      localStorage.setItem(pageInLs, prev + 1);
      return prev + 1;
    });
  }

  function handleDecrementPage() {
    setPage((prev) => {
      localStorage.setItem(pageInLs, prev - 1);
      return prev - 1;
    });
  }

  function handleChangeLastPage() {
    localStorage.setItem(pageInLs, totalPages);
    setPage(totalPages);
  }

  function handleChangeFirstPage() {
    localStorage.setItem(pageInLs, 1);
    setPage(1);
  }

  return (
    <Flex mt="22px" justify={{ sm: "center", md: "flex-start" }}>
      <Stack direction="row" spacing="10px">
        <Button leftIcon={<ArrowLeftIcon w="10px" />} disabled={page === 1} onClick={handleChangeFirstPage} />
        <Button leftIcon={<ChevronLeftIcon />} disabled={page === 1} onClick={handleDecrementPage} />
        <Button>{page}</Button>
        <Button rightIcon={<ChevronRightIcon />} disabled={page === totalPages} onClick={handleIncrementPage} />
        <Button rightIcon={<ArrowRightIcon w="10px" />} disabled={page === totalPages} onClick={handleChangeLastPage} />
      </Stack>
    </Flex>
  );
}
