import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";

import { Input, InputGroup, InputLeftAddon, Stack, Text, Button, Flex, Image, useToast } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import CheckNotVerifiedMenu from "./CheckNotVerifiedMenu";

import useLocalStorage from "hooks/useLocalStorage";
import { dateConvert } from "helper/dateConvert";

const PhotoCheckComponent = ({ data }) => {
  const toast = useToast();
  const router = useHistory();
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [img, setImg] = useState("");
  const [reason, setReason] = useState("Выберете причину отклонения чека");
  const [value, setValue] = useState({
    summary: "",
    time: "",
    fd: "",
    fn: "",
    fp: "",
    type: "1",
  });
  const [fnsResponse, setFnsResponse] = useState({
    fns: "",
    successFlag: false,
    errorFlag: false,
  });
  const { setItem, getItem } = useLocalStorage(`checkInputValue:${data.id}`);

  function getImg() {
    const imgType = String(data.image).split(".");
    const checkImg = `${process.env.REACT_APP_API_URL}/qrcodes/${data.image}.${imgType[1]}`;

    setImg(checkImg);
    setShow(!show);
  }

  function getSummaryValue(e) {
    const filteredValue = e.target.value.replace(/[^\d.]/g, "");
    const validFormat = /^(\d+)?(\.\d{0,2})?$/.test(filteredValue);

    if (validFormat) {
      setValue({ ...value, [e.target.name]: filteredValue });
    }
  }

  function getFdFpValue(e) {
    const filteredValue = e.target.value.replace(/[^\d]/g, "");
    const validFormat = /^\d{0,10}$/.test(filteredValue);

    if (validFormat) {
      setValue({ ...value, [e.target.name]: filteredValue });
    }
  }

  function getFnValue(e) {
    const filteredValue = e.target.value.replace(/[^\d]/g, "");
    const validFormat = /^\d{0,16}$/.test(filteredValue);

    if (validFormat) {
      setValue({ ...value, [e.target.name]: filteredValue });
    }
  }

  function getDateValue(e) {
    setValue({ ...value, [e.target.name]: e.target.value });
  }

  async function checkFns() {
    try {
      setIsLoading(true);
      setItem(value);

      const body = { ...value, time: value.time + ":00" };

      const response = await axios.post(`${process.env.REACT_APP_API_URL}/qr/fns-check`, body);

      if (response.data.Code !== "200") {
        setFnsResponse({
          ...fnsResponse,
          fns: [response.data.Message],
          errorFlag: true,
          successFlag: false,
        });
        throw new Error(response.data.Message);
      }

      const checkItems = JSON.parse(response.data.Ticket).content.items.map((el) => el.name);

      setFnsResponse({
        ...fnsResponse,
        fns: checkItems,
        errorFlag: false,
        successFlag: true,
      });

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error(error.message);
    }
  }

  async function saveCheckData() {
    try {
      const body = {
        id: data.id,
        summary: value.summary,
        time: value.time + ":00",
        fd: value.fd,
        fn: value.fn,
        fp: value.fp,
        fns_response: JSON.stringify(fnsResponse.fns),
        status: "wait",
        type: "manual",
      };
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/qr/update-qrcode`, body);

      if (response.data) {
        toast({
          title: "Чек сохранен",
          status: "success",
          isClosable: true,
          duration: 3000,
          position: "top",
        });
      }

      window.location.reload();
    } catch (error) {
      console.error(error.message);
    }
  }

  async function notVerifyCheck() {
    try {
      if (!reason) throw new Error("Выберите причину отклонения чека");
      if (reason === "Выберете причину отклонения чека") throw new Error("Выберите причину отклонения чека");

      await axios.post(`${process.env.REACT_APP_API_URL}/qr/change-qrcode-status`, {
        status: "notVerified",
        reason: reason,
        qrcodeId: data.id,
      });

      await axios.post(`${process.env.REACT_APP_API_URL}/notifications`, {
        account_id: data.account_id,
        entity_type: "Чек отклонен",
        entity_id: data.id,
        notification_data: `Чек от: ${dateConvert(data.create_date)}`,
      });

      toast({
        title: `Чек отклонен`,
        status: "error",
        isClosable: true,
        duration: 3000,
        position: "top",
      });

      router.goBack();
    } catch (error) {
      console.error(error.message);
      toast({
        title: error.message,
        status: "info",
        isClosable: true,
        duration: 3000,
        position: "top",
      });
    }
  }

  useEffect(() => {
    if (!getItem()) return;
    setValue(getItem());
  }, []);

  return (
    <>
      <Flex direction="column" w="100%" gap="10">
        <Button w={{ sm: "100%", md: "max-content" }} onClick={() => router.goBack()}>
          Назад
        </Button>

        <Card width="100%">
          <CardHeader mb="15px">
            <Flex direction="column" gap="10px">
              <Text fontSize="lg" fontWeight="bold">
                Чек № {data.id}
              </Text>
            </Flex>
          </CardHeader>

          <CardBody>
            <Stack direction="column" spacing="10px" w="100%">
              <Text fontWeight="bold">Дата загрузки: {dateConvert(data.create_date)}</Text>
              {fnsResponse.errorFlag && (
                <Text color="red" fontSize="sm" textAlign="center">
                  {fnsResponse.fns}
                </Text>
              )}

              {fnsResponse.successFlag && (
                <Flex direction="column">
                  <Text color="green" fontWeight="bold" textAlign="center">
                    ЧЕК НАЙДЕН
                  </Text>

                  {fnsResponse.fns.map((item, index) => (
                    <Text fontSize="sm" key={index}>
                      {item}
                    </Text>
                  ))}
                </Flex>
              )}

              <InputGroup>
                <InputLeftAddon w="80px">Сумма</InputLeftAddon>
                <Input
                  name="summary"
                  type="text"
                  maxLength={10}
                  placeholder="Введите сумму"
                  onChange={getSummaryValue}
                  value={value.summary}
                />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon w="80px">Дата</InputLeftAddon>
                <Input name="time" type="datetime-local" max="9999-12-31T23:59" onChange={getDateValue} value={value.time} />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon w="80px">ФН</InputLeftAddon>
                <Input name="fn" type="text" maxLength={16} placeholder="Введите ФН чека" onChange={getFnValue} value={value.fn} />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon w="80px">ФД</InputLeftAddon>
                <Input name="fd" type="text" maxLength={10} placeholder="Введите ФД чека" onChange={getFdFpValue} value={value.fd} />
              </InputGroup>
              <InputGroup>
                <InputLeftAddon w="80px">ФП</InputLeftAddon>
                <Input name="fp" type="text" maxLength={10} placeholder="Введите ФП чека" onChange={getFdFpValue} value={value.fp} />
              </InputGroup>

              <Flex justify={{ sm: "space-evenly", md: "flex-start" }} gap="10px">
                <Button w="max-content" isLoading={isLoading} colorScheme="blue" onClick={checkFns}>
                  Проверить
                </Button>

                <Button w="max-content" colorScheme="green" disabled={!fnsResponse.fns} onClick={saveCheckData}>
                  Сохранить
                </Button>

                <CheckNotVerifiedMenu reason={reason} setReason={setReason} />

                <Button w="max-content" colorScheme="red" onClick={notVerifyCheck}>
                  Отклонить
                </Button>
              </Flex>
            </Stack>
          </CardBody>
        </Card>

        <Flex>
          <Button w={{ sm: "100%", md: "max-content" }} onClick={getImg}>
            Фото чека
          </Button>
        </Flex>

        <Flex direction="column" align="center" gap="15px">
          {show && <Image src={img} alt="No check image" width="50%"></Image>}
        </Flex>
      </Flex>
    </>
  );
};

export default PhotoCheckComponent;
