import { useEffect, useMemo, useState } from "react";
import ApexCharts from "react-apexcharts";

import { Box, Grid, Text } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";

import { membersOptions, checksOptions, checkAnalytics } from "./ChartsOptions";

import useRequestArray from "hooks/useRequestArray";

function Stat() {
  const membersData = useRequestArray("statistics", "get");
  const [membersStats, setMembersStats] = useState({
    series: [{ name: "участники", data: [0, 0, 0] }],
  });

  const checksData = useRequestArray("qr/stat", "get");
  const [checkStat, setCheckStat] = useState({
    series: [{ name: "чеки", data: [0, 0, 0] }],
  });

  const [reason, setReason] = useState({ series: [0], options: {} });
  const [avgCheckSum, setAvgCheckSum] = useState({ series: [{ name: "сумма", data: [0] }] });

  const activeMembers = useMemo(() => {
    return membersData.responseData?.filter((el) => el.status === "active").length;
  }, [membersData.responseData]);
  const notActiveMembers = useMemo(() => {
    return membersData.responseData?.filter((el) => el.status === "not active").length;
  }, [membersData.responseData]);

  useEffect(() => {
    setMembersStats({
      series: [
        {
          name: "участники",
          data: [membersData.responseData.length, activeMembers, notActiveMembers],
        },
      ],
    });
  }, [membersData.responseData]);

  const verifiedChecks = useMemo(() => {
    return checksData.responseData?.filter((el) => el.qrcode_status === "verified").length;
  }, [checksData.responseData]);
  const notVerifiedChecks = useMemo(() => {
    return checksData.responseData?.filter((el) => el.qrcode_status === "notVerified").length;
  }, [checksData.responseData]);
  const waitChecks = useMemo(() => {
    return checksData.responseData?.filter((el) => el.qrcode_status === "wait").length;
  }, [checksData.responseData]);

  useEffect(() => {
    setCheckStat({
      series: [
        {
          name: "чеки",
          data: [checksData.responseData.length, verifiedChecks, notVerifiedChecks, waitChecks],
        },
      ],
    });
  }, [checksData.responseData]);

  const filteredChecks = useMemo(() => {
    return checksData.responseData.filter((el) => el.summary !== null && el.summary !== "none");
  }, [checksData.responseData]);
  const checkAmountSum = useMemo(() => {
    return filteredChecks.reduce((sum, el) => +el.summary + sum, 0);
  }, [filteredChecks]);
  const res = useMemo(() => {
    return filteredChecks.length > 0 ? Math.round(checkAmountSum / filteredChecks.length) : 0;
  }, [checkAmountSum, filteredChecks]);

  useEffect(() => {
    setAvgCheckSum({ series: [{ name: "сумма", data: [res] }] });
  }, [checksData.responseData]);

  useEffect(() => {
    const reasons = checksData.responseData.reduce((acc, obj) => {
      const reason = obj.reason;

      if (reason !== null && reason !== "Одобрен модератором" && reason !== "Автоматическая проверка пройдена") {
        acc[reason] = (acc[reason] || 0) + 1;
      }

      return acc;
    }, {});

    const reasonData = {
      series: Object.values(reasons),
      options: {
        chart: {
          type: "bar",
        },
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          labels: {
            colors: "#64748b",
            useSeriesColors: false,
          },
        },
        labels: Object.keys(reasons),
      },
    };

    setReason(reasonData);
  }, [checksData.responseData]);

  return (
    <>
      <Grid templateColumns={{ sm: "1fr", md: "repeat(2, 1fr)" }} gap="24px" pt={{ sm: "75px" }}>
        <Card>
          <CardHeader>
            <Text fontSize="lg" fontWeight="bold">
              Участники
            </Text>
          </CardHeader>

          <CardBody h="100%">
            <Box w="100%" h="100%" minH={{ sm: "200px", lg: "300px" }}>
              <ApexCharts series={membersStats.series} options={membersOptions.options} type="bar" />
            </Box>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Text fontSize="lg" fontWeight="bold">
              Чеки
            </Text>
          </CardHeader>

          <CardBody h="100%">
            <Box w="100%" h="100%" minH={{ sm: "200px", lg: "300px" }}>
              <ApexCharts series={checkStat.series} options={checksOptions.options} type="bar" />
            </Box>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Text fontSize="lg" fontWeight="bold">
              Аналитика по чекам
            </Text>
          </CardHeader>

          <CardBody h="100%">
            <Box w="100%" h="100%" minH={{ sm: "200px", lg: "300px" }}>
              <ApexCharts series={avgCheckSum.series} options={checkAnalytics.options} type="bar" />
            </Box>
          </CardBody>
        </Card>

        <Card>
          <CardHeader>
            <Text fontSize="lg" fontWeight="bold">
              Причины отклонения чеков
            </Text>
          </CardHeader>

          <CardBody h="100%">
            <Box w="100%" h="100%" minH={{ sm: "200px", lg: "300px" }}>
              <ApexCharts series={reason.series} options={reason.options} type="pie" />
            </Box>
          </CardBody>
        </Card>
      </Grid>
    </>
  );
}

export default Stat;
