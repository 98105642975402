import { Flex, Text } from "@chakra-ui/react";

import Card from "components/Card/Card";

import CheckPatternTable from "./components/CheckPatternTable";
import AddCheckItemPopover from "./components/AddCheckItemPopover";

import useRequestArray from "hooks/useRequestArray";

export default function CheckPattern() {
  const { refetch, responseData, isError } = useRequestArray("check-pattern", "get");

  return (
    <Flex direction="column" pt={{ sm: "125px", md: "75px" }} gap="20px">
      <AddCheckItemPopover refetch={refetch} />

      {responseData && !isError && <CheckPatternTable data={responseData} refetch={refetch} />}

      {isError && (
        <Card>
          <Text textAlign="center">Нет товаров для автомодерации</Text>
        </Card>
      )}
    </Flex>
  );
}
