import { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Input,
  Flex,
} from "@chakra-ui/react";

import ItemTypeMenuComponent from "./ItemTypeMenuComponent";

import useRequest from "hooks/useRequest";

export default function AddCheckItemPopover({ refetch }) {
  const [value, setValue] = useState({
    patternName: "",
    itemType: "",
    itemTypeNameId: "",
  });
  const { postData } = useRequest("check-pattern");

  async function createCheckPattern() {
    let errorMessage = "Товар существует или ошибка создания";

    if (!value.itemType) errorMessage = "введите тип товара";
    if (!value.patternName) errorMessage = "введите наименование товара";

    await postData(value, { errorTitle: errorMessage });
    await refetch();
    setValue({ ...value, patternName: "" });
  }

  function getInputValue(e) {
    setValue({ ...value, [e.target.name]: e.target.value });
  }

  const handleSelectItemType = (type, id) => {
    setValue((prev) => ({
      ...prev,
      itemType: type,
      itemTypeNameId: id,
    }));
  };

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button>Добавить товар</Button>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverHeader>Введите наименование товара</PopoverHeader>
        <PopoverBody>
          <Flex direction="column" gap="20px" mt="10px">
            <Input name="patternName" placeholder="Введите наименования товара" value={value.patternName} onChange={getInputValue} />

            <ItemTypeMenuComponent handleSelectItemType={handleSelectItemType} />

            <Button colorScheme="green" onClick={createCheckPattern}>
              Сохранить
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
