import { useMemo } from "react";

export default function useSearchValue(data, searchValue, page) {
  const filteredData = useMemo(() => {
    const filteredData = data?.filter((item) => {
      const dataKey = Object.keys(item);

      return dataKey.some((key) => String(item[key]).trim().toLowerCase().includes(searchValue.trim().toLowerCase()));
    });

    return filteredData;
  }, [data, searchValue, page]);

  return filteredData;
}
