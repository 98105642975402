import { useState } from "react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  Button,
  Input,
  Flex,
} from "@chakra-ui/react";

import useRequest from "hooks/useRequest";

export default function AddCheckItemPopover({ refetch }) {
  const [value, setValue] = useState({ typeName: "" });
  const { postData } = useRequest("item-type");

  async function createCheckPattern() {
    await postData(value, { errorTitle: "Товар существует или ошибка создания" });
    await refetch();

    setValue({ typeName: "" });
  }

  function getInputValue(e) {
    setValue({ ...value, [e.target.name]: e.target.value });
  }

  return (
    <Popover placement="bottom-start">
      <PopoverTrigger>
        <Button>Добавить товар</Button>
      </PopoverTrigger>

      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />

        <PopoverHeader>Введите наименование товара</PopoverHeader>
        <PopoverBody>
          <Flex direction="column" gap="20px" mt="10px">
            <Input name="typeName" placeholder="Введите наименования товара" value={value.typeName} onChange={getInputValue} />

            <Button colorScheme="green" onClick={createCheckPattern}>
              Сохранить
            </Button>
          </Flex>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
