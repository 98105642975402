import { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { Box, Button, Flex, FormControl, FormLabel, Input, Link, Text, useColorModeValue } from "@chakra-ui/react";

import basic from "assets/img/basic-auth.png";

function SignUp() {
  const history = useHistory();
  const titleColor = useColorModeValue("teal.300", "teal.200");
  const textColor = useColorModeValue("gray.700", "white");
  const bgColor = useColorModeValue("white", "gray.700");
  const [inputValue, setInputValue] = useState({
    login: "",
    password: "",
  });
  const [data, setData] = useState({
    responseData: [],
    isLoading: false,
    isError: "",
  });

  async function createAdmin() {
    try {
      if (!inputValue.login) throw new Error("email require");
      if (!inputValue.password) throw new Error("password require");

      setData({ ...data, isLoading: true });
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/admin/registration`, inputValue);
      console.log(response.data);
      // setData({ ...data, isLoading: false, responseData:  });
      history.push("auth/login");
    } catch (error) {
      setData({ ...data, isLoading: false, isError: "Ошибка регистрации" });
      console.error(error.message);
    }
  }

  return (
    <Flex direction="column" alignSelf="center" justifySelf="center" overflow="hidden">
      <Box
        position="absolute"
        minH={{ base: "70vh", md: "50vh" }}
        w={{ md: "calc(100vw - 50px)" }}
        borderRadius={{ md: "15px" }}
        left="0"
        right="0"
        bgRepeat="no-repeat"
        overflow="hidden"
        zIndex="-1"
        top="0"
        bgImage={basic}
        bgSize="cover"
        mx={{ md: "auto" }}
        mt={{ md: "14px" }}
      ></Box>
      <Flex direction="column" textAlign="center" justifyContent="center" align="center" mt="6.5rem" mb="30px">
        <Text fontSize="2xl" color="white" fontWeight="bold">
          Добро пожаловать
        </Text>
      </Flex>
      <Flex alignItems="center" justifyContent="center" mb="60px" mt="20px">
        <Flex
          direction="column"
          w="445px"
          background="transparent"
          borderRadius="15px"
          p="40px"
          mx={{ base: "100px" }}
          bg={bgColor}
          boxShadow="0 20px 27px 0 rgb(0 0 0 / 5%)"
        >
          <Text fontSize="xl" color={textColor} fontWeight="bold" textAlign="center" mb="22px">
            Регистрация
          </Text>

          <FormControl>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Электронная почта
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              name="login"
              type="email"
              placeholder="Введите электронную почту"
              mb="24px"
              size="lg"
              onChange={(e) => setInputValue({ ...inputValue, [e.target.name]: e.target.value })}
            />
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Пароль
            </FormLabel>
            <Input
              fontSize="sm"
              ms="4px"
              borderRadius="15px"
              type="password"
              name="password"
              placeholder="Введите пароль"
              mb="24px"
              size="lg"
              onChange={(e) => setInputValue({ ...inputValue, [e.target.name]: e.target.value })}
            />

            <Button
              type="submit"
              bg="teal.300"
              fontSize="sm"
              color="white"
              fontWeight="bold"
              w="100%"
              h="45"
              mb="24px"
              _hover={{
                bg: "teal.200",
              }}
              _active={{
                bg: "teal.400",
              }}
              onClick={createAdmin}
            >
              Зарегистрироваться
            </Button>
          </FormControl>
          <Flex flexDirection="column" justifyContent="center" alignItems="center" maxW="100%" mt="0px">
            <Text color={textColor} fontWeight="medium">
              Уже зарегистрированы?
              <Link color={titleColor} as="span" ms="5px" href="#" fontWeight="bold" onClick={() => history.push("auth/login")}>
                Войти
              </Link>
            </Text>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUp;
