import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

import { Flex, Text } from "@chakra-ui/react";

import UserTable from "./components/UserTable";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import InputComponent from "./components/InputComponent";
import MenuComponent from "./components/MenuComponent";
import SkeletonComponent from "./components/SkeletonComponent";
import PagePagination from "./components/PagePagination";

import useLocalStorage from "hooks/useLocalStorage";
import useSearchValue from "hooks/useSearchValue";
import useRequestArray from "hooks/useRequestArray";

function Users() {
  const router = useHistory();
  const usersLimitLs = useLocalStorage("usersLimit");
  const usersPage = useLocalStorage("usersPage");
  const [order, setOrder] = useState(localStorage.getItem("userOrder") || "DESC");
  const [limitOnPage, setLimitOnPage] = useState(usersLimitLs.getItem() || "5");
  const [page, setPage] = useState(usersPage.getItem() || 1);
  const [searchValue, setSearchValue] = useState(localStorage.getItem("searchUserValue") || "");
  const allUsers = useRequestArray(`accounts?order=${order}&limit=${searchValue.length ? "ALL" : limitOnPage}&page=${page}`, "get");
  const filteredUser = useSearchValue(allUsers.responseData.data, searchValue, page);

  function openUserCard(id) {
    router.push(`/dashboard/user-card/${id}`);
  }

  function handleChangeLimitOnPage(e) {
    const target = e.target.name;

    setLimitOnPage(target);
    setPage(1);

    usersLimitLs.setItem(target);
    usersPage.setItem(1);
  }

  return (
    <Flex direction="column" w="100%" pt={{ sm: "125px", md: "75px" }}>
      <InputComponent searchValue={searchValue} setSearchValue={setSearchValue} />

      <Flex mb="25px">
        <MenuComponent limitOnPage={limitOnPage} handleChangeLimitOnPage={handleChangeLimitOnPage} />
      </Flex>

      <Card mb="25px">
        <CardBody>
          <CardBody justifyContent="center">
            {allUsers.responseData.data && !allUsers.isError && !allUsers.isLoading && (
              <UserTable data={filteredUser} openUserCard={openUserCard} setOrder={setOrder} order={order} orderType={"userOrder"} />
            )}
            {allUsers.isError && <Text>Нет пользователей по заданным параметрам</Text>}
          </CardBody>
          {allUsers.isLoading && <SkeletonComponent height={100} />}
        </CardBody>
      </Card>

      {limitOnPage !== "ALL" && searchValue.length === 0 && (
        <PagePagination page={page} setPage={setPage} totalPages={allUsers.responseData.totalPages} pageInLs="usersPage" />
      )}
    </Flex>
  );
}
export default Users;
