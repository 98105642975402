import { CheckCircleIcon, CloseIcon, QuestionIcon, EmailIcon, NotAllowedIcon, DownloadIcon } from "@chakra-ui/icons";
import { Table, Tbody, Th, Thead, Tr, Td, Flex, Text, Menu, MenuButton, MenuList, MenuItem, Link, IconButton } from "@chakra-ui/react";

import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";

import useRequest from "hooks/useRequest";

const WinnersTable = ({ data, openUserCard, openQrCard, refetch }) => {
  const { postData } = useRequest("notify/send-email");

  async function updateEmailStatus(id) {
    await postData({ id });
    refetch();
  }

  function openImage(image, type) {
    return `${process.env.REACT_APP_API_URL}/api/static/${type}/${image}`;
  }

  function getDocumentData(documents, type) {
    if (!documents) return "-";
    const parseDocument = JSON.parse(documents);

    return (
      <Menu>
        <MenuButton as={IconButton} aria-label="Options" icon={<DownloadIcon />} variant="outline" />

        <MenuList maxHeight="200px" overflowY="auto">
          {parseDocument.map((el, index) => (
            <MenuItem key={index}>
              <Link isExternal href={openImage(el, type)}>
                {el}
              </Link>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    );
  }

  return (
    <Card overflowX="scroll">
      <CardBody>
        <Table variant="striped" size="sm">
          <Thead flex="column" justifyContent="space-between">
            <Tr color="gray.500">
              <Th color="gray.500">Id пользователя</Th>
              <Th color="gray.500">Email пользователя</Th>
              <Th color="gray.500">ФИО</Th>
              <Th color="gray.500">Номер телефона</Th>
              <Th color="gray.500">Статус пользователя</Th>
              <Th color="gray.500">Чек</Th>
              <Th color="gray.500">Период</Th>
              <Th color="gray.500">Приз</Th>
              <Th color="gray.500">Фото Паспорта</Th>
              <Th color="gray.500">Договора</Th>
              <Th color="gray.500">Статус отправки письма</Th>
              <Th color="gray.500">Ответ почтового сервиса</Th>
              <Th color="gray.500">Отправить письмо</Th>
            </Tr>
          </Thead>

          <Tbody>
            {data.map((row, index) => {
              return (
                <Tr key={index}>
                  <Td>
                    <Flex align="center">
                      <Text w="max-content" role="button" fontSize="md" fontWeight="bold" onClick={() => openUserCard(row.accounts_id)}>
                        {row.accounts_id || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" fontSize="md" fontWeight="bold">
                        {row.email || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" color="gray.400" fontSize="md">
                        {row.fio || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" fontSize="md" fontWeight="bold">
                        {row.phone || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" fontSize="md" fontWeight="bold">
                        {row.status === "active" ? "Подтвержденный пользователь" : ""}
                        {row.status === "not active" ? "Неподтвержденный пользователь" : ""}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center" w="min-content">
                      <Text w="max-content" color="gray.400" fontSize="md" role="button" onClick={() => openQrCard(row.qrcode_id)}>
                        {row.qrcode_id || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" color="gray.400" fontSize="md">
                        {row.raffle_type || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" color="gray.400" fontSize="md">
                        {row.prize}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" color="gray.400" fontSize="md">
                        {getDocumentData(row.photo_path, "passport")}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" color="gray.400" fontSize="md">
                        {getDocumentData(row.scan_contract, "contracts")}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center" justify="center">
                      <Text w="max-content" color="gray.400" fontSize="md">
                        {row.email_send && <CheckCircleIcon color="green" />}
                        {!row.email_send && row.email_send_message === null && <QuestionIcon />}
                        {!row.email_send && row.email_send_message !== null && <CloseIcon color="red" />}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center">
                      <Text w="max-content" color="gray.400" fontSize="md">
                        {row.email_send_message || "-"}
                      </Text>
                    </Flex>
                  </Td>

                  <Td>
                    <Flex align="center" justify="center">
                      {row.email_send_message === "success" ? (
                        <Text w="max-content" color="gray.400" fontSize="md">
                          <NotAllowedIcon w={7} h={7} />
                        </Text>
                      ) : (
                        <Text w="max-content" color="gray.400" fontSize="md" onClick={() => updateEmailStatus(row.id)}>
                          <EmailIcon w={7} h={7} role="button" />
                        </Text>
                      )}
                    </Flex>
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </CardBody>
    </Card>
  );
};

export default WinnersTable;
